'use client';
import { MouseEventHandler } from 'react';
import cn from 'classnames';
import Icon from '@/components/global/Icon';
import Text from '@/components/global/Text';
import { pushDataLayer } from '@/helpers';
import css from '../../style.module.scss';
import { useTranslations } from 'next-intl';
import { Link } from '@/i18n/routing';

export const FAQBlock = () => {
  const t = useTranslations('faqBlock');
  const goToFAQ: MouseEventHandler = (e) => {
    pushDataLayer({
      event: 'footer_faq_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  return (
    <Link href="/faq" onClick={goToFAQ} className={css.faq}>
      <div className={css.header}>
        <Text className={cn(css.title, 'uppercase')}>faq</Text>
        <Icon name="arrow-short" className={css.arrow} />
      </div>
      <Text className={cn(css.question, css.main)}>{t('question1')}</Text>
      <Text className={cn(css.question, css.secondary)}>{t('question2')}</Text>
    </Link>
  );
};
