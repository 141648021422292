import { FC, memo, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import cn from 'classnames';

//* Config
import { RoutesItem } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import CustomLink from '@/components/global/CustomLink';
import Button from '@/components/global/Button';
import { YNButton } from '../YNButton';
import css from './style.module.scss';
import { useTranslations } from 'next-intl';

type BurgerMenuProps = {
  headerMenus: RoutesItem[];
  isBurgerMenuOpen: boolean;
};

const BurgerMenu: FC<BurgerMenuProps> = memo(
  ({ isBurgerMenuOpen, headerMenus }) => {
    const pathname = usePathname();
    const t = useTranslations();

    const storeMenus = useMemo(() => {
      return headerMenus.map((item, index) => {
        return (
          <CustomLink
            className={cn(css.burgerMenuLink, {
              [css.active]: pathname.includes(item.path),
            })}
            key={index}
            url={item.path}
          >
            <Text className={css.text}>{t(item.nameLocale) || item.name}</Text>
          </CustomLink>
        );
      });
    }, [headerMenus]);

    return (
      <div
        className={cn(css.wrapper, {
          [css.active]: isBurgerMenuOpen,
        })}
      >
        <div className={css.menusWrap}>
          {storeMenus}
          <YNButton isActive={pathname.includes('/yansnotes/')} />
          <Button
            external
            url={'https://personal-account.owner.one/en/signin/'}
            variant="secondary"
            className={css.joinWaitList}
          >
            {t('clientsOnly')}
          </Button>
        </div>
      </div>
    );
  }
);

export default BurgerMenu;
