'use client';
import { CSSProperties, FC, RefObject, useState } from 'react';
import { MenuProps, Dropdown } from 'antd';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import cn from 'classnames';
import { usePathname, useRouter } from '@/i18n/routing';
import Icon from '../Icon';
import { languageMap } from '@/utils/languages';
import { withUIContext } from '@/context';
import { pushDataLayer } from '@/helpers';
import css from './style.module.scss';

type LanguageSelectorProps = {
  className?: string;
  overlayClassName?: string;
  offset?: [number, number];
  minified?: boolean;
  isInFooter?: boolean;
  ref?: RefObject<HTMLDivElement | null>;
  style?: {
    borderRadius: number;
    background: string;
    hoverColor: string;
    hoverBackground: string;
    secondaryTextColor: string;
    color: string;
  };
  disableDomScroll: () => void;
  enableDomScroll: () => void;
};

export const LanguageSelector = withUIContext<LanguageSelectorProps>(
  ({
    className,
    overlayClassName,
    offset = [-48, -2],
    minified,
    isInFooter,
    ref,
    style = {
      borderRadius: 0,
      background: 'var(--dark100)',
      hoverColor: '#fff',
      secondaryTextColor: 'var(--dark200)',
      hoverBackground: '#797979',
      color: '#fff',
    },
    disableDomScroll,
    enableDomScroll,
  }) => {
    const t = useTranslations();
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);
    const params = useParams<{ lang: keyof typeof languageMap }>();
    let lang = params.lang;
    if (!lang || !Object.keys(languageMap).includes(lang)) {
      lang = 'en';
    }
    const pathname = usePathname();

    const languageItems: MenuProps['items'] = [
      {
        label: (
          <div className={cn({ [css.dropdownSelected]: lang === 'en' })}>
            <span>{languageMap.en.full}</span>
            {lang === 'en' && <Icon name="check" />}
          </div>
        ),
        key: 'en',
        onClick: () => {
          if (lang !== 'en') {
            router.replace(pathname, { locale: 'en' });
            router.refresh(); // https://github.com/amannn/next-intl/issues/1514
          }
        },
      },
      ...Object.entries(languageMap).map(([key, value]) => {
        if (key === 'en') return null;
        return {
          label:
            key === lang ? (
              <div className={css.dropdownSelected}>
                <div>
                  <span>{value.full}</span>{' '}
                  <span className={css.autoTranslationMark}>
                    AI translation
                  </span>
                </div>
                <Icon name="check" />
              </div>
            ) : (
              value.full
            ),
          key: key,
          onClick: () => {
            router.replace(pathname, { locale: key });
            router.refresh();
          },
        };
      }),
    ];

    return (
      <Dropdown
        trigger={['click']}
        overlayClassName={cn(css.dropdownWrapper, overlayClassName, {
          [css.isInFooter]: isInFooter,
        })}
        placement="bottom"
        onOpenChange={(b) => {
          if (b) disableDomScroll();
          else enableDomScroll();
          setIsOpen(b);
        }}
        align={{ offset }}
        menu={{
          items: languageItems,
        }}
        overlayStyle={
          {
            '--border-radius': `${style.borderRadius}px`,
            '--background': style.background,
            '--hoverColor': style.hoverColor,
            '--hoverBackground': style.hoverBackground,
            '--secondaryTextColor': style.secondaryTextColor,
            '--color': style.color,
          } as CSSProperties
        }
      >
        <div
          className={cn(css.dropdown, className, {
            [css.open]: isOpen,
            [css.minified]: minified,
          })}
          ref={ref}
          style={
            {
              '--border-radius': `${style.borderRadius}px`,
              '--background': style.background,
              '--color': style.color,
            } as CSSProperties
          }
        >
          <Icon name="globe1" className={css.dropdownIcon} />
          <span className={css.dropdownText}>
            {minified ? languageMap[lang].code : languageMap[lang].full}
          </span>
          <Icon name="arrow-down" className={css.dropdownArrow} />
        </div>
      </Dropdown>
    );
  },
  ['disableDomScroll', 'enableDomScroll']
);
