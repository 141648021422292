import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"argent.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"argentRegular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"argentLight.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"argentDemiBold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--argent\",\"preload\":false}],\"variableName\":\"argent\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"avenirRegular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"avenirMedium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"avenirDemi.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--avenir\",\"preload\":false}],\"variableName\":\"avenir\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"raleway.woff2\",\"variable\":\"--raleway\",\"preload\":false}],\"variableName\":\"raleway\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"urbanist-VF.woff2\",\"style\":\"normal\"},{\"path\":\"urbanist-Italic.woff2\",\"style\":\"italic\"}],\"variable\":\"--urbanist\",\"fallback\":[\"raleway\"],\"preload\":false}],\"variableName\":\"urbanist\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"caveatRegular.woff2\",\"style\":\"normal\"},{\"path\":\"caveatBold.woff2\",\"style\":\"normal\",\"weight\":\"700\"}],\"variable\":\"--caveat\",\"preload\":false}],\"variableName\":\"caveat\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"roboto.woff2\",\"variable\":\"--roboto\",\"preload\":false}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"notoSerif.woff2\",\"variable\":\"--notoSerif\",\"preload\":false}],\"variableName\":\"notoSerif\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./merchant-Thin.woff2\",\"style\":\"normal\",\"weight\":\"100\"},{\"path\":\"./merchant-ExtraLight.woff2\",\"style\":\"normal\",\"weight\":\"200\"},{\"path\":\"./merchant-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./merchant-Bold.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"./merchant-Black.woff2\",\"style\":\"normal\",\"weight\":\"900\"}],\"variable\":\"--merchant\",\"fallback\":[\"raleway\"],\"preload\":false}],\"variableName\":\"merchant\"}");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../icons/fonts/Owner-One.woff2\",\"variable\":\"--icons\",\"display\":\"block\"}],\"variableName\":\"icons\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/AppFooter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/AppHeader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/CookieHub/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/Notification/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/Popup/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/ScrollWrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/src/components/global/TwitterPixel/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["UtmWrapper"] */ "/opt/showroom-new/frontend/src/components/global/UtmWrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/context/providers/UIProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useActiveSection"] */ "/opt/showroom-new/frontend/src/helpers/_useActiveSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["useResizeObserver"] */ "/opt/showroom-new/frontend/src/helpers/_useResizeObserver.ts");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/styles/global.scss");
